
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AddMemberModal from "@/views/interactive-marketing/wechat-communities/AddMember.vue";
import AddcommunityModal from "@/views/interactive-marketing/wechat-communities/AddCampaign.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ELDatatable } from "magicbean-saas-common";
import { ApiWechatCommunity, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  commonChangeDefaultDate,
  formatDate,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "wechat-communities-information",
  components: {
    ELDatatable,
    AddMemberModal,
    AddcommunityModal,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      name: "",
      manager: "",
      purpose: "",
      dedicated_group: [],
      description: "",
      mb_segmentation: [],
      dedicated_group_level: [],
      members: "",
      start_date: "",
      expiry_date: "",
      pro_active_actions: [],
      subsisting_actions: [],
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      campaign_list: [],
      rfm_tacking: {
        last_thirty_days_gtv: "",
        last_thirty_days_login_frequency: 0,
        last_thirty_days_order: "",
        total_gtv: "",
        total_login_frequency: 0,
        total_order: "",
      },
      __show: {
        created_user: "",
        updated_user: "",
      },
    });

    const options = ref({
      campaignPurposeOptions: new Map([]),
      pro_active_actions: [],
      subsisting_actions: [],
      communityMemberLoading: true,
      communityMemberList: [],
      communityMemberColumns: [
        {
          label: t("influencerAccount.memberId"),
          prop: "system_id",
        },
        {
          label: t("influencerAccount.fullname"),
          prop: "__show.name",
        },
        {
          label: t("influencerAccount.mobile"),
          prop: "__show.mobile",
        },
        {
          label: t("influencerAccount.wechatAccount"),
          prop: "__show.wechat_account",
        },
        {
          label: t("influencerAccount.assignedGroup"),
          prop: "__show.assigned_group",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      communityMemberCurrentPage: 1,
      communityMemberTotal: 0,
      communityMemberTotalPage: 0,
      communityMemberPageSize: 10,
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      purpose: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      manager: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      members: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // dedicated_group_level: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const modelId = ref(0);

    const managerOptions = ref([]);
    const managerLoading = ref<boolean>(false);
    const purposeOptions = ref([]);
    const dedicatedGroupLevelOptions = ref([]);
    const dedicatedGroupLevelLoading = ref<boolean>(false);

    const campaignsTableData = ref([]);

    const getInfluencerGroupSourceData = async () => {
      dedicatedGroupLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      dedicatedGroupLevelLoading.value = false;
      if (data.code == 0) {
        dedicatedGroupLevelOptions.value = data.data;
      }
    };
    const getUserSourceData = async (value?: string, id?: string) => {
      managerLoading.value = true;
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      managerLoading.value = false;
      if (data.code == 0) {
        managerOptions.value = data.data;
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);

    const searchManagerItems = (query) => {
      debounceUserSearch(query);
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_campaign_purpose",
          "wechat_community_pro-active_actions",
          "wechat_community_subsisting_actions",
        ],
      });
      if (data.code == 0) {
        purposeOptions.value = data.data.distribution_campaign_purpose.items;
        options.value.pro_active_actions =
          data.data["wechat_community_pro-active_actions"]["items"];
        options.value.subsisting_actions =
          data.data.wechat_community_subsisting_actions.items;
        data.data.distribution_campaign_purpose.items.map((item, index) => {
          options.value.campaignPurposeOptions.set(item.value, item.label);
        });
      }
    };

    const getWechatCommunityInfo = (type = 0) => {
      loading.value = true;
      ApiWechatCommunity.getWechatCommunityInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            if (type == 0) {
              formData.value = data.data;
              if (data.data.manager != "") {
                getUserSourceData("", data.data.manager);
              }
            } else {
              formData.value.campaign_list = data.data.campaign_list;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleAddModel = () => {
      modelId.value = 0;
    };

    const handleEditModel = (index, row) => {
      modelId.value = row.id;
    };

    const updateModelList = () => {
      getWechatCommunityInfo(1);
    };

    const deleteWechatCommunityCampaign = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } =
            await ApiWechatCommunity.deleteWechatCommunityCampaign({
              campaign_id: row.id,
              wechat_community_id: route.params.id,
            });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateModelList();
            });
          }
        }
      });
    };

    const getWechatCommunityMemberList = async () => {
      options.value.communityMemberLoading = true;
      const { data } = await ApiWechatCommunity.getWechatCommunityMemberList({
        wechat_community_id: route.params.id,
        page: options.value.communityMemberCurrentPage,
        page_size: options.value.communityMemberPageSize,
      });
      options.value.communityMemberLoading = false;
      if (data.code == 0) {
        options.value.communityMemberList = data.data.items;
        options.value.communityMemberTotal = data.data.total;
        options.value.communityMemberTotalPage = data.data.total_page;
      }
    };

    const handleDeleteMemberModel = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          options.value.communityMemberLoading = true;
          const { data } = await ApiWechatCommunity.deleteWechatCommunityMember(
            {
              account_id: row.id,
              wechat_community_id: route.params.id,
            }
          );
          options.value.communityMemberLoading = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              getWechatCommunityMemberList();
            });
          }
        }
      });
    };

    const setItemsPerPage = (val) => {
      options.value.communityMemberPageSize = parseInt(val);
      getWechatCommunityMemberList();
    };

    const handleCurrentChange = (val: number) => {
      options.value.communityMemberCurrentPage = val;
      getWechatCommunityMemberList();
    };

    const getModelStatusLabel = (row, column, cellValue, index) => {
      const status = new Map([
        ["10", t("marketingCampaigns.plan")],
        ["20", t("marketingCampaigns.processing")],
        ["30", t("marketingCampaigns.completed")],
        ["40", t("marketingCampaigns.void")],
      ]);
      return status.get(String(cellValue));
    };

    const getModelPurposeLabel = (row, column, cellValue, index) => {
      return options.value.campaignPurposeOptions.get(String(cellValue));
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiWechatCommunity.updateWechatCommunity(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteWechatCommunity = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Wechat Communities?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiWechatCommunity.deleteWechatCommunity({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Wechat Communities has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "wechat-communities" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const getStatusLabel = (status) => {
      let str = "",
        statusClass = "";
      switch (status) {
        case 10:
          str = t("marketingCampaigns.plan");
          statusClass = "badge-light-info";
          break;
        case 20:
          str = t("marketingCampaigns.processing");
          statusClass = "badge-light-warning";
          break;
        case 30:
          str = t("marketingCampaigns.completed");
          statusClass = "badge-light-success";
          break;
        case 40:
          str = t("marketingCampaigns.void");
          statusClass = "badge-light-danger";
          break;
        default:
          break;
      }
      return { str, statusClass };
    };

    onBeforeMount(() => {
      getWechatCommunityInfo();
      getDropdownOptions();
      getInfluencerGroupSourceData();
      getWechatCommunityMemberList();
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDate,
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      options,
      managerOptions,
      managerLoading,
      purposeOptions,
      dedicatedGroupLevelOptions,
      dedicatedGroupLevelLoading,
      campaignsTableData,
      modelId,
      searchManagerItems,
      handleAddModel,
      handleEditModel,
      updateModelList,
      deleteWechatCommunityCampaign,
      getWechatCommunityMemberList,
      handleDeleteMemberModel,
      setItemsPerPage,
      handleCurrentChange,
      getModelStatusLabel,
      getModelPurposeLabel,
      submit,
      deleteWechatCommunity,
      getStatusLabel,
    };
  },
});
