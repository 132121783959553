
import { defineComponent, onBeforeMount, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiCampaign, ApiWechatCommunity } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-member-modal",
  props: {
    id: { type: Number, default: 0 },
    parentId: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addCampaignModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      wechat_community_id: props.parentId,
      campaign_id: "",
    });

    const rules = ref({
      campaign_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          // getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const campaignOptions = ref([]);
    const campaignLoading = ref<boolean>(false);

    const getCampaignsSourceData = async () => {
      campaignLoading.value = true;
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      campaignLoading.value = false;
      if (data.code == 0) {
        campaignOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWechatCommunity.addWechatCommunityCampaign(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addCampaignModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addCampaignModalRef.value);
      resetForm();
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getCampaignsSourceData();
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      addCampaignModalRef,
      submitButton,
      campaignOptions,
      campaignLoading,
      t,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
